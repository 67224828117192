/**
* DESKTOP
**/

.companyBlock {
    padding: 16px;
    margin-right: 15px;
    border: 1px solid #eaeaf0;
    border-radius: 6px;
    text-align: center;
    transition: box-shadow 0.3s;
    background-color: white;
    min-width: 0;

    &:hover {
        border-color: #FF7D55;

        .companyBlock__name {
            color: #FF7D55;
        }

    }

    &:last-child {
        margin-right: 0;
    }

    &__name {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        color: #333;
        height: 44px;
        margin-bottom: 6px;
        overflow: hidden;
        width: 100%;
    }

    &__box {
        cursor: pointer;
        height: 100%;
        position: relative;

        img {
            width: 128px;
            height: 128px;
            margin-bottom: 30px;
            object-fit: contain;
        }
    }

    &__tag {
        padding: 2px 6px;
        background-color: #48BBFF;
        border: rgba(0,90,255,0.3) solid 1px;
        text-transform: uppercase;
        color: #FFF;
        border-radius: 4px;
        font-weight: 700;
        font-size: 11px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.company-box-3 {
    .companyBlock {
        &__box {
            display: flex;
            align-items: center;

            img {
                margin-bottom: 0;
            }
        }

        &__content {
            padding-left: 30px;
            margin-bottom: 0;
            align-items: flex-start;
        }


    }

    .companyBlock__name {
        text-align: left;
        height: unset;
    }
}

.padding-16 {
    padding: 16px;
}

.featured-companies {
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.featured-companies::-webkit-scrollbar {
    display: none;
}

/**
* MOBILE
**/

@media all and (max-width: 991px) {
    .featured-companies {
        overflow: scroll;
        margin-left: -16px;
        margin-right: -16px;

        .companyBlock {
            &:first-child {
                margin-left: 16px;
            }
        }
    }

    .companyBlock {
        padding: 0;
        flex-shrink: 0;

        &__box {
            flex-direction: column;
            padding-bottom: 16px;

            img {
                width: 98px;
                height: 98px;
                margin-bottom: 8px;
                padding: 16px 8px;
            }
        }

        &__content {
            align-items: center;
            flex: 0 0 auto;
            padding: 0 4px;
        }

        &__name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding: 0 5px;
        }

        .list-company-upto3 {
            justify-content: center;

            .companyBlock__name {
                padding-left: 0;
            }
        }
    }

    .company-box-3 {
        .companyBlock {
            &__content {
                align-items: center;
                padding: 0 4px;
            }
        }
    }
}

@media all and (max-width: 900px) {
    .companyBlock {
        &__box {
            flex-wrap: wrap;
        }
    }
}