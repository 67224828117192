.premiumBanner {
	.title {
		font-size: 2.5rem;
		color: white;
		text-shadow: 0 2px 4px rgba(0, 0, 0, .5);
		margin-top: .5rem;
		margin-bottom: 2rem;
	}
	&_has-search-bar {
		position: absolute;
		top: 52%;
		width: 100%;
	}
}

.banner {

	@include mobile {
		//height: 374px;
	}

	& {background: linear-gradient(270deg, rgb(0, 90, 255) 0%, rgb(0, 23, 68) 100%)}

	&_default {
		background: linear-gradient(270deg, rgb(0, 90, 255) 0%, rgb(0, 23, 68) 100%)
	}

	&__image {
		background-size: cover !important;
		background-position: center top !important;
		width: auto;
		height: 35.15625vw;		
		margin: 0 !important;

		//@include tablet {
		//	height: 80vh;
		//	//min-height: 500px;
		//	//max-height: 600px;
		//}
		//
		//@include desktop {
		//	height: 83vh;
		//}

		@include mobile {
			height: 282px;
		}
	}
	.image {
		.banner__logo {
			width: auto;
			height: 46px;

			@include tablet {
				height: 52px;
			}
		}
	}
	&__content {
		@include tablet {
			margin-top: 48px;
			margin-left: 26%;
			flex: none;
			width: 45%;
		}

		@include mobile {
			padding: 0;
			
		}

		/*
		Search bar scale up base on banner width
		*/		
		@media screen and (min-width: 1280px) and (max-width: 1365px) {
			width: 410px !important;			
			height: 88.5px;
			top: 50%;
			margin-top: -44.25px;	
			margin-left: 38%;
		}
		@media screen and (min-width: 1440px) {
			width: 475px !important;			
			height: 88.5px;
			top: 50%;
			margin-top: -44.25px;	
			margin-left: 34%;	
		}		
	}
	&__message {
		font-size: 13px;
		padding: 8px 16px 8px 8px;
		color: $color-text;
		
		a {
			color: $color-secondary;
			font-weight: bold;
		}

		@include tablet {
			font-size: 18px;
			color: white;
			
			padding-left: 4px;
			padding-right: 22.5px;

			a {
				font-weight: normal;
			}
		}
	}
}

.section-hero-banner {
	.slick-active {
		position: relative; z-index: 2; // make the active slide above the other slides
	}

	@include tablet {
		margin-bottom: 0;
		.slick-dots {bottom: 35px;}
	}
}

.learningBanner {
	@include mobile {
		padding-left: 16px;
		padding-right: 16px;
	}
	
}
