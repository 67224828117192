.languageSwitcher {
    margin: 1.5rem 0;
    .dropdown-trigger {
        min-width: 12rem;
	}

	&_link {
		margin-top: 26px;
	}
	
	&__link-action {		
		color: $primary;
	}
}
