// Responsiveness
$gap: 64px;

// 1344px container + 4rem
$fullhd: 1392px + (2 * $gap);
$desktop: 1008px + (2 * $gap);
$widescreen: 1200px + (2 * $gap);

$newFullHd: $fullhd - $gap;
$newDesktop: $desktop - $gap;

$primary: $color-primary;
$text: $color-text;
$link-hover: $color-secondary;
$footer-background-color: #eeeeee;
$navbar-dropdown-arrow: $text;
$navbar-item-hover-color: $color-secondary;
$navbar-item-hover-background-color: none;
$navbar-dropdown-item-hover-color: white;
$navbar-dropdown-item-hover-background-color: $color-primary;
$navbar-burger-color: white;

$large-mobile-breakpoint: 426px;