$jobBlockFontColor: #333333;

.jobBlock {
    border: 1px solid #EAEAF0;
    border-radius: 4px;
    margin-bottom: 15px;
    height: 100px;
    background: #ffffff;

    &:hover {
        border-color: #99bdff;
    }

    &__logo {
        width: auto;
        margin: auto;
        object-fit: contain;
    }

    &__company {
        color: $jobBlockFontColor;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0;
    }

    &__title {
        color: $jobBlockFontColor;
        font-size: 14px;
        letter-spacing: 0.04px;
        line-height: 1.4;
        margin-bottom: 3px;
        font-weight: 600;

        &:hover {
            color: $link-hover !important;
        }
    }

    &__link {
        color: $text;

        &:hover {
            color: inherit;
        }
    }

    &__leftCol {
        padding: 0;
        margin-top: 3px;
        width: 58px;
        height: 58px;
    }

    &__rightCol {
        display: flex;
        flex: none;
        width: calc(100% - 58px);
        padding: 0;
        padding-left: 15px;
    }

    &__info.column {
        padding: 0;
    }

    .columns {
        margin: 0;
        height: 100%;
        width: 100%;
    }

    .tag {
        font-size: 10px;
        line-height: 15px;
        height: auto;
        padding: 2px 6px;
    }

    // Has to do like this to override Bulma column spacing
    #{&} &__right-col.column {
        padding-left: 0;
    }

    &-border-primary {
        border-color: #D9F5FD;
    }

    @include tablet {
        &__title {
            font-size: 16px;
            margin-bottom: 5px;
        }

        .tag {
            border-radius: 4px;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: -0.21px;
            line-height: 15px;
            text-align: center;
        }
    }
}