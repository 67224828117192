
#mobile-main-search-box-modal {

	.searchModal {
		background-color: #f8f8f8;
		height: 100%;

		// Search form
		&__form {
			padding: 10px 15px 10px;
			background: #fff;
			border-bottom: 1px solid #ebebeb;
			margin-bottom: 20px;

			.searchModal__box-search {
				display: flex;
				border-radius: 20px;
				align-items: center;
				background-color: rgba(255, 255, 255, 0.5);
			}

			.icon-go-back {
				margin-right: 20px; opacity: 0.5; transform: scale(1.4);
				display: flex;
				justify-content: center;
			}
			.icon-search {stroke: #00b9f2; fill: #00b9f2; stroke-width: 2px; margin-right: 10px;
				width: 20px; height: 20px; margin-top: -5px;}

			.searchModal__input {
				font-size: 16px;
				border: none;
				outline: none;
				background-color: transparent;
				box-shadow: none;
				margin: 0px;
				padding: 0px;

				&:focus, &:active {
					border: none;
					outline: none;
					background-color: transparent;
					box-shadow: none;
					margin: 0px;
					padding: 0px;
				}
			}
		}

		// Content below
		&__content {
			height: 100%;

			.searchModal__box {
				border-radius: 4px;
				margin-bottom: 20px;

				a {
					color: $color-primary;
					font-size: 16px;
				}
			}

			.searchModal__box-title {
				font-size: 13px;
				font-weight: bold;
				padding: 8px 15px;
				text-transform: uppercase;
				
			}

			.searchModal__suggestion-list {
				margin-left: 0;
				box-shadow: 0 1px 4px rgba(0,0,0,0.15);
				background: #fff;

				& .column:first-child {padding-top: 14px;}
				& .column:last-child {padding-bottom: 14px;}

				&_no-border {
					padding: 10px 15px;

					.searchModal__suggestion-item {
						border: none;
						padding: 5px 0;

						.tag {
							margin: 0;
						}
					}
				}
			}

			.searchModal__suggestion-item {
				font-size: 16px;
				border-top: 1px solid #ebebeb;
				padding: 8px 15px;

				a {color: #333333;}
			}
			.button-clear-all {margin-right: 15px; margin-top: -1px;}
			.placeholder-shimmer {margin-left: 15px;margin-right: 15px;}
			.hot-keyword-industry, .recent-total-jobs {color: #8d8d8d; margin-left: 5px;}

		}

		&__form-action {
			color: white;
			font-size: 17px;
			padding: 1rem 0 1rem 1rem;
		}
	}
}
