.centeredSearchBar {
	$root: &;
	/* For Desktop */
	& {position: absolute; top: 75%; width: 100%;z-index: 10;}
	.main-tag-line {
		font-size: 36px;
		font-weight: 300;
		color: #fff;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
		margin-left: 12px;
	}
	.searchBar {
		&__input-group { background-color: white; border-radius: 20px; }
		&__input-box { background-color: white; display: flex; width: 100%; align-items: center;
			border-radius: 4px;
			.searchBar_on-nav & { height: auto; padding: 0 5px; }
			&_has-expandable { position: relative; }
		}
		&__input {
			background: transparent; border: none; box-shadow: none; padding-left: 5px; color: #555555;
			font-size: 14px;
			&:focus {
				border: none !important;
				box-shadow: none !important;
			}
			&::-webkit-input-placeholder {
				color: #9B9B9B;
				font-size: 14px;
			}
			&::-moz-placeholder {
				color: #9B9B9B;
				font-size: 14px;
			}
			&:-ms-input-placeholder {
				color: #9B9B9B;
				font-size: 14px;
			}
			&::placeholder {
				color: #9B9B9B;
				font-size: 14px;
			}

			&_curved {
				border-radius: 20px;
			}
		}

		&__input-icon {
			left: 0.2rem;
			margin: 0 5px 0 15px;
		}
		&__button-wrapper {
			display: flex;
			align-items: center;
			padding-right: .5rem;
		}
		&__button {
			border-radius: 3px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			background-color: $color-secondary;
			color: $white;
			border: none;
			transition: background-color 0.3s;			
			flex: 0 0 16.5%;
			max-width: 16.49029982%;

			&:hover {
				color: $white;
				background-color: #d97b08;
			}

		}
		.field {
			margin-bottom: 0;
		}
		&__expand {
			&-wrapper {
				width: 54%;
				position: absolute;
				top: 42px;
				left: 0;
				background-color: white;
				z-index: $z-index-modal;
				@include box-normal();
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border: 1px solid #dbdbdb;
			}
		}

		.block-title {color: #6d6d6d; text-transform: uppercase;font-size: 10px; font-weight: 400; margin-bottom: 2px;
			display: block;}
		.recent-search-wrapper {
			padding: 20px; border-right: 1px solid #dbdbdb; height: 100%;
		}
		.hot-keyword-wrapper {padding: 20px 20px 20px 0;
			.block-title {margin-left: 6px;}
		}
		.clear-keyword {
			cursor: pointer;
			margin-right: 10px;
			svg {
				border-radius: 50%;
				fill: rgb(255, 255, 255);
				padding: 3px;
				background: rgb(153, 153, 153);
				opacity: 0.5;
				width: 15px;
				height: 15px;
				transition: all 0.3s ease 0s;
			}
		}
		.title-suggestion-wrapper {
			padding: 20px; border-right: 1px solid #dbdbdb; height: 100%;

			.job-title-suggestion {
				cursor: pointer; transition: color 0.3s; line-height: 27px;
				font-size: 13px;

				strong {color: inherit}
			}
			.job-title-suggestion:hover {color: #ff9900;
				strong {color: inherit}
			}
			.job-title-suggestion.is-active {color: #ff9900}
		}
		&__tag.tag {
			border: 1px solid $color-element-border; background-color: #fff; border-radius: 50px; padding: 5px 15px;
			margin: 4px; text-transform: capitalize; height: auto; line-height: 18px;
			transition: background-color 0.3s, border-color 0.3s;

			.tag-keyword {display: block; font-size: 13px; margin-right: 3px;}
			.tag-industries {display: block;font-size: 11px; color: #8e8e8e}

			&:hover, &:focus {background-color: #fff; border-color: #ff9900; text-decoration: none;}

			@include mobile {
				.tag-keyword {
					font-size: 16px;
				}
				.tag-industries {
					font-size: 14px;
				}
			}
		}
		&__recent-search-icon {
			margin: 5px;
			display: flex;
			align-items: center;
		}
		&__recent-job-title {
			color: #484848;
			font-size: 14px;
		}
		&__recent-number {
			color: #8d8d8d; margin-left: 10px;
		}
		&__recent-box {
			margin: .75rem .75rem .75rem 0;
		}
		&_on-nav {
			width: 100%;
		}
		&_on-nav &__input-group {
			background-color: white;
		}
		&_on-nav &__input {
			font-size: .75rem;
			height: 2.5rem !important;
		}
		&_on-nav &__input-icon {
			font-size: .75rem;
			top: .4rem !important;
		}
		&_on-nav &__button {
			font-size: 13px;
			padding: .25rem 1rem !important;
			background-color: $color-secondary;
		}
		&_on-nav &__expand-wrapper {
			position: absolute;
			top: 40px;
			left: 0;
			width: 230%;
			background-color: white;
			padding: 1.75rem;
			@include box-normal();
		}

		&_mobile {
			width: 90%;
			max-width: 32rem;
			.input {
				border: none;
				box-shadow: none;
				outline: none;
				border-radius: 20px;
				padding: 0px 5px 0px 10px;
			}
		}
		.navbar-item.is-expanded &__input {
			&::-webkit-input-placeholder {
				color: #9B9B9B;
				font-size: 13px;
			}
			&::-moz-placeholder {
				color: #9B9B9B;
				font-size: 13px;
			}
			&:-ms-input-placeholder {
				color: #9B9B9B;
				font-size: 13px;
			}
			&::placeholder {
				color: #9B9B9B;
				font-size: 13px;
			}
		}

		&__expand-no-recents {
			color: gray;
			font-style: italic;
			font-size: 13px;
		}

		&__SelectMulti {
			width: 600px;
			flex-basis: 520px;
			display:inline-flex;
			.location-icon {
				display: inline-flex;
				align-content: center;
				align-items: center;
			}
			> div {
				width: 100%;
				min-width: 260px;
				height: 42px;
				.select-container {
					border: 0 !important;
					width: 100%;
					height: 100%;
					.active-item {
						width: auto;
					}
					.select-dropdown {
						width: calc(100% - 6px) !important;
						top: 41px !important;
						left: 6px !important;
						.input-item {
							.filter-close {
								top: 9px;
							}
						}
					}
					.item-text {
						font-size: 13px;
					}
					&.show-checked {
						flex-wrap: inherit;
        				-webkit-flex-wrap: inherit;
					}
				}
				.multiple-select-selected-values {
					display: inline-flex;
					width: auto;
					height: 42px;
					align-content: center;
					align-items: center;
					.multiple-option-item {
						margin-bottom: 0;
						font-size: 13px;
					}
				}
			}
		}
	}
	> div {
		max-width: 70.5vw;
		margin: auto;
		@media screen and (max-width: 768px) {
			max-width: 92vw;
		}
		@media screen and (min-width: 769px) and (max-width: 1024px) {
			max-width: 90vw;
		}
		@media screen and (min-width: 3000px) and (max-width: 3288px) {
			width: 35vw;
		}
	}
	/* For Tablet & Mobile */
	//@include touch {
	//
	//}

	@include tablet {
		top: 42%;

		.searchBar {
			&__input-group {background-color: transparent;border-radius: 5px;border: 14px solid rgba(0, 0, 0, 0.35);}
			&__input-icon {font-size: 1.5rem;top: .5rem !important;}
			&__button {font-size: 14px;padding: 1.5rem 50px; font-weight: 600;}
		}
	}

	@include mobile {
		top: 86px;
		.main-tag-line {
			font-size: 22px;
			margin-left: 0;
			margin-bottom: 8px;
			font-weight: 500;
		}
		.searchBar {

			&__input-box {height: 50px;background-color: white;border-radius: 2px;box-shadow: 0 0 4px 0 $color-shadow-dark; flex-direction: row-reverse;}
			&__input {
				font-size: 14px;
				&::-webkit-input-placeholder {color: #9B9B9B;font-size: 14px;}
				&::-moz-placeholder {color: #9B9B9B;font-size: 14px;}
				&:-ms-input-placeholder {color: #9B9B9B;font-size: 14px;}
				&::placeholder {color: #9B9B9B;font-size: 14px;}
			}

			&__input-icon {
				margin: 0;margin-right: 2px; margin-left: 20px;
				height: 24px;
				width: 24px;
				svg {
					height: 24px;
					fill: $color-primary;
				}
			}
		}

	}

	/*
	Search bar scale up base on banner width
	*/
	@include tablet {
		&_scalable {
			z-index: 2;
		}
	}

	@media screen and (min-width: 1024px) and (max-width: 1280px) {
		&_scalable {
			position: static;
		}

		&_scaleComponent {
			position: absolute;
			width: 1280px;
			height: 156px;
			left: 50%;
			margin-left: -683px;
			top: 50%;
			margin-top: -78px;
			z-index: 2;
			transition: transform .3s;

			#{$root}__wrapper {
				position: relative;
				margin-top: 38px;
			}
		}
	}

	@media screen and (min-width: 1281px) and (max-width: 1365px) {
		&_scalable {
			position: static;
		}

		&_scaleComponent {
			position: absolute;
			width: 1366px;
			height: 156px;
			left: 50%;
			margin-left: -683px;
			top: 50%;
			margin-top: -78px;
			z-index: 2;
			transition: transform .3s;

			#{$root}__wrapper {
				position: relative;
				margin-top: 38px;
			}
		}
	}

	@media screen and (min-width: 1440px) {
		&_scalable {
			position: static;
		}

		&_scaleComponent {
			position: absolute;
			width: 1366px;
			height: 156px;
			left: 50%;
			margin-left: -683px;
			top: 50%;
			margin-top: -78px;
			z-index: 2;
			transition: transform .3s;

			#{$root}__wrapper {
				position: relative;
				margin-top: 38px;
			}
		}
	}
}
// en
@media screen and (min-width: 768px) and (max-width: 980px) {
	[class*="SearchBar-module_searchBarWrappper__"][class*="SearchBar-module_en__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 10px !important;
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1460px) {
	[class*="SearchBar-module_searchBarWrappper__"][class*="SearchBar-module_en__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 10px !important;
		}
	}
}

// vi
@media screen and (min-width: 1024px) and (max-width: 1034px) {
	[class*="SearchBar-module_searchBarWrappper__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 10px !important;
		}
	}
}

@media screen and (min-width: 1035px) and (max-width: 1089px) {
	[class*="SearchBar-module_searchBarWrappper__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 20px !important;
		}
	}
}

@media screen and (min-width: 1090px) and (max-width: 1200px) {
	[class*="SearchBar-module_searchBarWrappper__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 40px !important;
		}
	}
}

@media screen and (min-width: 1201px) and (max-width: 1260px) {
	[class*="SearchBar-module_searchBarWrappper__"] {
		[class^="SearchBar-module_navItem__"] {
			padding-right: 60px !important;
		}
	}
}