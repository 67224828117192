@charset "utf-8";

@import '~bulma/sass/utilities/_all.sass';

html.flo-no-scrolling {
	overflow: hidden;
	@include mobile {
		position: fixed;
		width: 100%;
		height: 100%;
	}
}
body.flo-no-scrolling {
	overflow: hidden;
	@include mobile {
		position: fixed;
		width: 100%;
		height: 100%;
	}
}

// provide varibales for all styles
@import 'variables';
// provide 3rd party library styles
@import 'vendors';
// provide styles for HTML element
@import 'base';
// provide helper styles, mixins and functions
@import 'modules';
// provide component styles
@import 'partials';
@import 'quickFix';

html {
	overflow-x: initial;
	overflow-y: initial !important;
}

.fixed-element {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
a {
	transition: color 0.3s;
}
.ellipsis {
	text-overflow: ellipsis;

	/* Required for text-overflow to do anything */
	white-space: nowrap;
	overflow: hidden;
}

div[class*='PopupUploadCV-module_wrap_'],
div[class*='UploadResumeModal-module_wrap_'] {
	div[class*='PopupUploadCV-module_wrapBody_'],
	div[class*='UploadResumeModal-module_wrapBody_'] {
		div[class*='PopupUploadCV-module_bodyContentStep2_'],
		div[class*='UploadResumeModal-module_bodyContentStep2_'] {
			p[class*='PopupUploadCV-module_title_'],
			p[class*='UploadResumeModal-module_title_'] {
				font-weight: 600;
			}
			div[class*='PopupUploadCV-module_row_'],
			div[class*='UploadResumeModal-module_row_'] {
				label {
					font-weight: 600;
				}
			}
		}
	}
}

.swiper-pagination-bullet {
	background-color: #ccc !important;
}

.listMenu-homepage span {
	line-height: 21px;
}

.zalo-widget {
	z-index: 9;
}
@media (max-width: 767px) {
	.appHeader {
		position: relative;
		z-index: 11;
	}
	.dim-header .appHeader {
		z-index: 9;
	}
}
