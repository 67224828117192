.placeholder {
	&_type-article {				
		height: 324px;
		margin: 0 0 2px 15px;

		@include large-device-only {
			height: 235px;
			padding-left: 8px;
			padding-right: 8px;
			margin: 0 8px 2px 8px;
		}

		@include tablet {
			height: 370px;
			margin: 4px 12px;
		}
	}
}
