.sectionBlock {
    padding: 24px 0;
    box-sizing: border-box;
    &:first-child {
        margin-top: 0;
    }
    .swiper-pagination-bullet-active {
        background-color: #005aff !important;
    }
    &_featured-company {
        background-color: #f2f7ff;
        padding-bottom: 40px !important;
        padding-top: 0 !important;
        background: linear-gradient(270deg, #005aff 0%, #001744 73.72%);
        &.christmas {
            background-image: initial;
            position: relative;
            &::before {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: 0;
                background-image: url(/assets-page-container/images/christmas/left_banner.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: left;
                width: 260px;
                height: 100%;
                max-height: 215px;
                z-index: -1;
            }
            &:after {
                content: ' ';
                position: absolute;
                right: 0;
                bottom: 0;
                background-image: url(/assets-page-container/images/christmas/right_banner.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: right;
                width: 389px;
                height: 100%;
                max-height: 390px;
                z-index: -1;
            }
        }
        /* Lunar new year theme */
        &.lunar-new-year {
            background-image: initial;
            position: relative;
            &::before {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: 0;
                background-image: url(https://images.vietnamworks.com/images/left_banner.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: left;
                width: 260px;
                height: 100%;
                max-height: 215px;
                z-index: -1;
            }
            &:after {
                content: ' ';
                position: absolute;
                right: 0;
                bottom: 0;
                background-image: url(https://images.vietnamworks.com/images/right_banner.svg?version=1);
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: right;
                width: 235px;
                height: 100%;
                max-height: 248px;
                z-index: -1;
            }
        }
        .section-title h2 {
            color: #fff;
        }
    }
    &__title {
        font-size: $font-size-section-title;
        font-weight: 600;
        color: $color-title;
        margin-bottom: 16px;
    }
    &_has-bg {
        padding: 1.5rem 0 2.5rem 0;
    }
    &_has-outside-padding {
        padding-left: 16px;
        padding-right: 16px;
    }
    &_no-padding {
        padding: 0 !important;
    }
    &_no-margin {
        margin: 0;
    }
    &_has-padding-touch {
        @include touch {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    &_company-spotlight {
        background-color: #fff;
    }
    &_job-list {
        .container {
            border: 1px solid #ccdeff;
            border-radius: 6px;
            .section-title {
                border-radius: 5px 5px 0 0;
                background-color: #f2f7ff;
                background-image: url(/assets-page-container/images/featured-jobs-background.png);
                background-repeat: no-repeat;
                background-position: left;
                background-size: contain;
                padding: 20px 25px 20px 40px;
                margin-bottom: 0;
                /* Christmas theme */
                &.christmas {
                    &::before {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-image: url(/assets-page-container/images/christmas/title_tag.svg);
                        background-size: contain;
                        height: 66px;
                        width: 272px;
                        z-index: 0;
                    }
                }
                &.christmas-bottom {
                    &::after {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-image: url(/assets-page-container/images/christmas/bottom_tag.svg);
                        background-size: contain;
                        width: 351px;
                        height: 161px;
                    }
                }
                /* Lunar new year theme */
                &.lunar-new-year-bottom {
                    &::after {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-image: url(https://images.vietnamworks.com/images/bottom_tag.svg);
                        background-size: contain;
                        width: 240px;
                        height: 222px;
                    }
                }
            }
            .sectionBlock__title {
                margin-bottom: 0;
            }
            .sectionBlock__content {
                padding: 24px;
                @media (max-width: 768px) {
                    padding: 12px;
                }
            }
        }
    }
    &__link {
        color: #005aff !important;
        text-transform: uppercase;
        &:hover {
            color: $link-hover !important;
        }
        a {
            color: #005aff !important;
            &:hover {
                color: $link-hover !important;
            }
        }
    }
    @include mobile {
        &.job-corner {
            padding: 16px;
            background: transparent;
        }
        &_overflow-mobile {
            padding: 0;
        }
    }
    @include tablet {
        padding-top: 50px;
        padding-bottom: 0;
        &__title {
            margin-bottom: 20px;
            font-size: $font-size-section-title-large-device;
            font-weight: 600;
        }
        &_has-slider {
            margin-bottom: 0;
        }
        &_company-spotlight {
            background: initial;
            padding-bottom: initial;
            .swiper-pagination-bullet-active {
                background-color: #005aff !important;
            }
        }
        &_job-list {
            .sectionBlock__title {
                margin-bottom: 20px;
            }
        }
        &__link {
            font-size: 16px;
        }
    }
}