.f-grow-1 {
    flex-grow: 1 !important;
}
.d-flex {
    display: flex;
}

.column.is-85 {
    width: 70%;
}

@include tablet {
	.column.w-30-p-tablet {
		flex: none;
		width: 30%;
	}
}
