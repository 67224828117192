.icon {
    &_overlay {
        position: absolute;
        right: 6px;
        bottom: 10px;
        color: $color-secondary;
    }
    &_grey {
        color: #979996;
    }
    &__background-image {
        background-size: 85%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
        border: 0.5px solid #e8e8e8;
    }
    &__company-placeholder {
        background-image: url('https://images.vietnamworks.com/img/company-default-logo.svg');
	}
	
	&_svg {
		svg {
			height: $size-icon;
			fill: $color-primary;
		}		
	}

	&_alignment {
		position: relative;
		svg {
			position: absolute;
			top: 11px;
		}
		
	}
}
