.hr-insider {
    .cardBlock {
        margin: 0 0 2px 15px;
        box-shadow: none;
    }

    &.sectionBlock {
        padding-right: 0;
        padding-left: 0;
    }

    .sectionBlock {
        &__title {
            margin-bottom: 16px;
            padding: 0 16px;
        }
    }

    .article-wrapper {
        display: flex;
        overflow-y: scroll;
        flex: 100%;

        article {
            min-width: 64%;
        }
    }

    .article-wrapper::-webkit-scrollbar {
        display: none;
    }

    @include large-device-only {
        padding-left: 8px;
        padding-right: 8px;

        .cardBlock {
            margin: 0 8px 2px 8px;
        }
    }

    @include tablet {
        margin-bottom: 48px;

        .sectionBlock {
            &__title {
                font-size: 22px;
                text-align: left;
                font-weight: 600;
                padding: 0;
				text-transform: capitalize;
            }
        }

        .sectionBlock__content {
            margin-left: -15px;
            margin-right: -15px;
        }

        .cardBlock {
            margin: 4px 10px;
        }

        .article-wrapper {
            overflow: hidden;

            article {
                min-width: unset;
                width: 25%;
            }
        }
    }
}
