.job-corner {
    &__text {
        font-size: 24px;
        font-weight: 200;

        @include mobile {
            text-align: center;
        }
    }

    &__button {

        background-color: $orange;
        color: white;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
        transition: 0.3s background-color;
        padding: 10px 34px;
        height: auto;
        margin-top: 10px;
        border-color: $orange;

        &:hover {
            background-color: #d97b08;
            color: #fff;
            border-color: $orange;
        }
    }

    &__image {
        height: 274px;
        width: 100%;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 6px;

        &-right {
            position: absolute;
            width: 50px;
            top: 0;
            bottom: 0;
            background: white;
            opacity: 0.5;
            right: 0;
        }
    }

    &__image-link {
        display: block;
    }

    &__container {
        position: relative;
    }

    &__container-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mobile {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 500px;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
    }
}