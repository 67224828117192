@mixin box-normal() {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

@mixin large-device-only {
	@media screen and (min-width: 426px) and (max-width: $tablet - 1px) {
		@content;
	}
}
