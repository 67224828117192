.swiper-container {
    .jobBlock {
        padding: 12px 16px;
    }

    .tag {
        position: absolute;
        top: 14px;
        right: 16px;
    }

    .section-featured-jobs & .jobBlock__title {
        padding-right: 50px;
    }

    .pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .swiper-pagination {
            text-align: center;
            transition: transform 0.3s;
            width: unset !important;
            max-width: 180px;
            white-space: nowrap;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0 4px;
                transition: background-color 0.3s;
                cursor: pointer;
                background: #ccc;

                & {
                    transition: transform 0.3s, background-color 1s, border-color 1s;
                }

                &:hover {
                    background: #e5e5ea;
                }

                &-active-main {
                    display: inline-block;
                }

                &-active-prev,
                &-active-next {
                    display: inline-block;
                }

                &-active-prev-prev,
                &-active-next-next {
                    display: inline-block;
                }

                &-active {
                    background: #005AFF;
                }

                &-active:hover {
                    background: #005AFF;
                }
            }
        }

        .dot-bar {
            max-width: 180px;
            overflow: hidden;
        }

        .pagination-inner-wrapper {
            position: relative;
        }

        .swiper-button-prev,
        .swiper-button-next {
            &:hover {
                opacity: 0.7;
                stroke: #ff9900;
            }
        }

        .swiper-button-next {
            transform: rotateY(180deg);
            left: auto;
            right: -35px;
        }

        .swiper-button-disabled {
            opacity: 0.5;
        }

        .swiper-button-disabled:hover {
            opacity: 0.5;
            stroke: #6d6d6d;
            cursor: default;
        }

        .swiper-pagination-lock,
        .swiper-button-lock {
            display: none;
        }

        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination-bullet {
            &:focus {
                outline: none;
            }
        }
    }
}