.trendingIndustry {
    &__title {
        font-size: $font-size-section-title;
        font-weight: 300;
        text-align: center;
        margin-bottom: 1.5rem;

        @include tablet {
            font-weight: 600;
            margin-bottom: 3rem;
        }
    }
    &__icon {
    
        img {
            height: 60px;
            width: auto;
        }
        & .iconGreenColor{
            color:#50E3C2;
        }
        & .iconBlueColor{
            color:#00B9F2;
        }
    }
    &__box {
        padding: 2rem;
        width: 125px;
        margin: auto;
        border-radius: 50%;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
        background-color: white;
    
       
        &:hover {
            background-color: $color-primary;
            
            .trendingIndustry__number, .trendingIndustry__name {
                color: white;
               
            }
            & .trendingIndustry__icon {
                & .iconGreenColor{
                    color:white;
                }
                & .iconBlueColor{
                    color:white;
                }
            }
           
        }
    }
    &__number {
        color: $color-secondary;
    }
    &__name {
        color: $color-primary;        
        font-weight: 600;        
        font-size: 17px;
        line-height: 20px;
        text-align: center;
    }

    @include tablet {
        &__info-wrapper {
            margin-top: 8px;
        }
    }
}