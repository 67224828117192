.cardBlock {
    margin: 0 6px;
    border: 1px solid $color-border;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .15);

    &:hover {
        border-color: #FF7D55;

        .cardBlock__title {
            color: #FF7D55;
        }

    }

    &__category {
        color: $color-subtitle;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 16px;
    }

    &__title {
        font-size: 15px;
        line-height: 22px;
        font-weight: 600;
        margin: 8px 0;
        height: 66px;
        overflow: hidden;
    }

    &__content-wrapper {
        padding: 0 1rem 1rem 1rem;
    }

    &__content {
        font-size: 14px;
        height: 42px;
        overflow: hidden;
    }

    &__point {
        display: inline-block;
        margin-right: 6px;
        width: 10px;
        height: 10px;
        background-color: #FF7D55;
    }

}

@include tablet {
    .cardBlock {
        margin: 0 15px;
        box-shadow: none;
        border-radius: 6px;


        &__title {
            font-size: 16px;
            font-weight: 600;
            margin: 6px 0;
        }

        &__image {
            margin-bottom: 0;

            img {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }
        }

        &__content {
            font-size: 14px;
            height: 84px;
            overflow: hidden;
        }

        &__category {
            display: flex;
            align-items: center;
            color: #757575;
            font-size: 14px;
            font-weight: normal;
            text-transform: none;
            margin: 16px 0 0 0;
			height: 21px;

            span:last-child {
				overflow: hidden;
				flex: calc(100% - 10px);
				text-overflow: ellipsis;
            }
        }

        &__content-wrapper {
            padding: 1rem;
        }
    }
}
