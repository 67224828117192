.recoJobs {
    padding: 0 24px;

    @include tablet {
        padding: 0;
    }

    &__wrapper {
        font-size: $font-size-small;

        @include tablet {
            font-size: initial;
        }

        padding: 0 1rem;
    }
}

.swiper-container {
    .jobBlock {
        padding: 11.25px;
        position: relative;
    }

    .section-featured-jobs & .jobBlock__title {
        padding-right: 50px;
    }

    .pagination-wrapper {
        .swiper-pagination {
            transition: transform 0.3s;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin: 0 4px;
                transition: background-color 0.3s;
                cursor: pointer;
                background: #ccc;

                & {
                    transition: transform 0.3s, background-color 1s, border-color 1s;
                }

                &:hover {
                    background: #e5e5ea;
                }

                &-active-main {
                    display: inline-block;
                }

                &-active-prev,
                &-active-next {
                    display: inline-block;
                }

                &-active-prev-prev,
                &-active-next-next {
                    display: inline-block;
                }

                &-active {
                    background: #005AFF;
                }

                &-active:hover {
                    background: #005AFF;
                }
            }
        }

        .dot-bar {
            overflow: hidden;
        }

        .pagination-inner-wrapper {
            position: relative;
        }

        .swiper-button-prev,
        .swiper-button-next {
            fill: none;
            stroke: #6d6d6d;
            stroke-width: 2px;
            display: inline-block;
            width: 22px;
            height: 22px;
            opacity: 1;
            position: absolute;
            top: 0;
            left: -35px;
            cursor: pointer;
            transition: opacity 0.3s, stroke 0.3s;

            &:hover {
                opacity: 0.7;
                stroke: #ff9900;
            }
        }

        .swiper-button-next {
            transform: rotateY(180deg);
            left: auto;
            right: -35px;
        }

        .swiper-button-disabled {
            opacity: 0.5;
        }

        .swiper-button-disabled:hover {
            opacity: 0.5;
            stroke: #6d6d6d;
            cursor: default;
        }

        .swiper-pagination-lock,
        .swiper-button-lock {
            display: none;
        }

        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination-bullet {
            &:focus {
                outline: none;
            }
        }
    }
}