/* Placeholder Shimmer */
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

.placeholder-shimmer {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #F6F6F6;
	background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
	background-size: 800px 104px;
	height: 96px;
	position: relative;
	border-radius: 7px;
}

/* FadeIn Animation */

.animated {
	animation-duration: 0.3s;
	animation-fill-mode: both;

	&.take-1-second {
		animation-duration: 1s;
	}
}

.animated.infinite {
	animation-iteration-count: infinite;
}

@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}

.fadeIn {
	animation-name: fadeIn;
}

@keyframes fadeOut {
	from {opacity: 1;}
	to {opacity: 0;}
}

.fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}

@keyframes fadeOutDown {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

.fadeOutDown {
	animation-name: fadeOutDown;
}
