$nav-height-mobile: 3.25rem;
$nav-height-desktop: 56px;

$nav-item-padding-y: 1rem;
$nav-item-padding-x: .75rem;

// Colors
$sky-blue: #00B9F2;
$light-blue: #F7FDFF;
$dark-blue: #036C9A;
$orange: #FF7D55;
$gray72: #484848;
$gray74: #4a4a4a;
$gray101: #656565;
$gray118: #767676;
$gray155: #9B9B9B;
$gray209: #DBDBDB;
$gray214: #D6D6D6;
$gray229: #E5E5E5;
$gray236: #ECECEC;
$gray248: #F8F8F8;
$redTag: #F96B6B;

$color-primary: $sky-blue;
$color-primary-light: $light-blue;
$color-secondary: $orange;
$color-text: $gray74;
$color-strong-text: $gray155;
$color-title: #333333;
$color-subtitle: $gray101;
$color-employer: $dark-blue;
$color-border: $gray209;
$color-small-element-border: $gray229;
$color-element-border: $gray214;
$color-shadow: $gray236;
$color-shadow-dark: rgba(0,0,0,.25);
$color-footer-link-color: $gray118;
$color-company-spotlight-bg: $gray248;
$color-dot-dark: $gray72;
$color-dot-dark-border: $gray155;

// Typography
$font-size-small: .875rem;
$font-size-large: 24px;
$font-size-x-large: 30px;

$font-size-section-title: 18px;
$font-size-section-title-large-device: 22px;

// Sizing
$size-icon: 15px;

// Z-Indexes
$z-index-highest: 999;
$z-index-top: 100;
$z-index-medium: 50;

$z-index-modal: $z-index-top;
$z-index-overlay: $z-index-medium;
