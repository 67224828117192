html {
    font-size: 14px;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

a {
    &:hover {
        color: $color-secondary;
    }

    &.text-like {
        color: inherit;

        &:hover {
            color: $color-secondary;
        }
    }
}

hr {
    background-color: #DCDCDC;
    height: 1px;
}

strong {
    font-weight: 600;
}