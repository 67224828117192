.button {
    &.button_secondary {
        border: 1px solid $color-secondary;
        color: $color-secondary;
        padding: .5rem 2rem;
        &:hover {
            background-color: $color-secondary;
            color: white;
        }
    }

    &_secondary-bg#{&} {
        background-color: $color-secondary;
        color: white; 
    }
}