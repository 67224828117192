/** Fix overscrolling / page bouncing effect on iOS Safari */
@mixin fixSafariIOSPageBouncing() {
	html {
		width: 100%;
		height: 100%;
		position: relative;
	}
	body #vnwApp {
		height: calc(100%);
		overflow: hidden;
	}
	#pageContentWrapper {
		overflow-x: hidden
	}
}

@include touch {
	@include fixSafariIOSPageBouncing;		
}

#pageContentWrapper {
	@include tablet {
		background: #ffffff;
	}
}

/** Workaround to align the logo to the center of the page */
.vnwHeader {
	@include mobile {
		.vnwHeader__logo {
			width: 100%;
			justify-content: center;
			h2 {
				margin-left: -20px;
			}
		}
		.vnwHeader__searchFilterGroup, .vnwHeader__flexRight {
			display: none
		}

	}

}

/* Quick fix for onboarding */
.vnw-modal-on-boarding__upload-cv .drag-drop-area .help.text-danger {
	color: #ef474f;
	strong {
		color: #ef474f;
	}
}
