.navbar {
	background-color: $color-primary;

	-webkit-transition: background-color .3s;
	transition: background-color .3s;

	&-start.f-grow-1 {
		.navbar-item {
			&:hover {
				color: white;
				text-shadow: 0px 0px 1px white;
				background-color: hsla(194, 100%, 37%, 1);
			}
		}
	}
	@include desktop {
		background-color: white;
		height: $nav-height-desktop;
	}

	.navbar-brand {
		.navbar-item {
			@include tablet {
				margin-right: 2rem;
			}
			padding: .25rem .25rem;
		}
		img {
			max-height: 2.6rem;
		}
	}
	.navbar-item {
		padding: $nav-item-padding-y $nav-item-padding-x;
		&:last-child {border-radius: inherit;}
		&.has-dropdown {
			padding: 0;
		}
		&_employer {
			color: $color-employer;
			&:hover {
				color: $color-employer;
			}
		}
		&_has-image {
			svg, img {
				margin-left: .5rem;
			}
		}
		&_heading {
			color: $color-primary;
			text-transform: uppercase;
		}

		&_hover-white {
			&:hover {
				color: white;
				&:last-child {
					border-radius: inherit;
				}
				.icon {
					color: white;
				}
			}
		}

	}
	.navbar-link {
		padding-left: $nav-item-padding-x;
		&__arrow {
			margin-left: 10px;
			transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			display: flex;
			align-items: center;
		}
	}

	.navbar-dropdown {
		
		font-size: 1rem;
		border-top: none;
		top: 48px;
		max-width: 375px;
		box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
		padding-top: 0px;
		padding-bottom: 0px;
		width: 375px;
		&_set-width {
			width: 22rem;
		}
		&.is-right {
			left: -180px;
			right: auto;
			& a.navbar-item {
				padding-right: initial;
				white-space: normal;
				padding: 12px 15px;
				word-break: break-word;
				min-width: 300px;
				display: flex;

				&:hover {
					&:last-child {
						border-radius: inherit;
					}
				}

				& .icon {
					padding: 24px;
					background-color: white;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
		}

		a.navbar-item {
			transition: none;
		}

	}

	.navbar-divider {
		height: 1px;
		&_short {
			width: 94%;
			margin: auto
		}
		&_heading {
			background-color: $color-primary;
		}
		&__no-margin {
			margin: 0px;
		}
	}

	.navbar-menu {
		.icon-container {
			& {width: 20px;height: 20px;display: inline-block; transition: fill 0.3s;}
			&:hover {fill: #ff9900}
		}
	}

	&_is-fixed-active {
		background-color: $color-primary;
		.navbar-item, .navbar-link {
			color: white;
		}
		.navbar-dropdown .navbar-item {
			color: $color-text;
		}
		.navbar-link:not(.is-arrowless)::after {
			border-color: white;
		}
	}

	img.navbar-account-icon {
		max-height: 2rem;
		border-radius: 20px;
	}

	.navbar-brand {
		padding-left: .5rem;
		padding-right: .5rem;
		@include tablet {
			padding-left: 0;
			padding-right: 0;
		}
		@include mobile {
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
	}

	.navbar-burger {
		margin-left: initial;
		span {
			height: 2px;
		}
	}

	& &__user-profile {
		width: 300px;
	}
	&__notifications-container {		
		overflow-y: auto;
		max-height: 300px;

		& a.navbar-item {
			font-size: 13px;			
			&.item-unread {
				background-color: #f9f9f9;
				@include tablet {
					&:hover, &:focus {
						background-color: #00B9F2;
						color: #fff;
					}
				}
			}
		}
	}
	&-item__recommended-job {
		position: relative;
		font-size: 13px;
		border-top: 1px solid rgba(0, 185, 242, .5);
		background: #f2fcff;
		border-radius: inherit;
	}
	&__header-moveup {
		& .navbar-dropdown {
			width: auto;
		}
	}
	& a.navbar-item.navbar-item__small-txt {
		font-size: 13px;
	}
}

.has-navbar-fixed-top {
	padding-top: $nav-height-mobile;
	@include desktop {
		padding-top: $nav-height-desktop;
	}

}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-transition: opacity .15s linear;
	transition: opacity .15s linear;
	-webkit-animation-duration: .35s;
	animation-duration: .35s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
