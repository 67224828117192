.modal {
    &_full-screen {
        height: 100%;
        max-height: initial;
    }
    .modal-content {
        background: $gray248;
        height: 100%;
	}
	
	z-index: $z-index-modal;
}
