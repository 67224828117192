// @import '~bulma';

/**
    Review Bulma Usages
**/

// Utilities Bulma
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/derived-variables";
// @import "~bulma/sass/utilities/animations";
@import "~bulma/sass/utilities/mixins";
@import "~bulma/sass/utilities/controls";

// Base Bulma
@import "~bulma/sass/base/minireset";
@import "~bulma/sass/base/generic";
@import "~bulma/sass/base/helpers";

// Element Bulma
// @import "~bulma/sass/elements/box";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/elements/container";
@import "~bulma/sass/elements/content";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/elements/icon";
@import "~bulma/sass/elements/image";
@import "~bulma/sass/elements/notification";
@import "~bulma/sass/elements/progress";
@import "~bulma/sass/elements/table";
@import "~bulma/sass/elements/tag";
@import "~bulma/sass/elements/title";
// @import "~bulma/sass/elements/other";

// Component Bulma
// @import "~bulma/sass/components/breadcrumb";
@import "~bulma/sass/components/card";
@import "~bulma/sass/components/dropdown";
@import "~bulma/sass/components/level";
@import "~bulma/sass/components/list";
@import "~bulma/sass/components/media";
@import "~bulma/sass/components/menu";
// @import "~bulma/sass/components/message";
@import "~bulma/sass/components/modal";
@import "~bulma/sass/components/navbar";
@import "~bulma/sass/components/pagination";
// @import "~bulma/sass/components/panel";
// @import "~bulma/sass/components/tabs";

// Grid Bulma
@import "~bulma/sass/grid/columns";
// @import "~bulma/sass/grid/tiles";

// Layout Bulma
@import "~bulma/sass/layout/hero";
@import "~bulma/sass/layout/section";
@import "~bulma/sass/layout/footer";

// Default :
// @import "~bulma/sass/utilities/_all";
// @import "~bulma/sass/base/_all";
// @import "~bulma/sass/elements/_all";
// @import "~bulma/sass/components/_all";
// @import "~bulma/sass/grid/_all";
// @import "~bulma/sass/layout/_all";

@import "components/navbar";
@import "components/tag";
@import "components/button";
@import "components/icon";
@import "components/modal";

.column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: $column-gap;
	min-width: 0;
}

.columns.is-variable .column {
	padding: var(--columnGap);
}

.container {
	@media screen and (min-width: $newDesktop) and (max-width: $desktop - 1px) {
		max-width: $desktop - $container-offset;
	}
}
