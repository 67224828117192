$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('0', 0),
    ('1', 0.125),
    ('2', 0.25),
    ('3', 0.5),
    ('4', 1.5),
    ('5', 2),
    ('6', 4),
    ('7', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

@function sizeValue($key, $value) {
    @return if($key == 0, 0, $value + $sizeUnit);
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue) !important;
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue) !important;
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
        }
        .#{$paddingKey}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
        }
    }
}

.col-jobBlock {
    @include tablet {
        > .column {                 
            &:nth-child(odd) {
                padding-right: 54px;
              }
            &:nth-child(even) {
                padding-left: 54px;
            }
        }
    }
}

.columns.is-gapless-outer {
    > .column {
        &:nth-child(odd) {
            padding-right: 3px;
            
        }
        &:nth-child(even) {
            padding-left: 3px;            
        }
    }
}

.column {
    &.has-tag {
        @include tablet {
            flex: none;
            width: 12.667%;
        }
    }
}
