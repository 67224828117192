.sectionBlock_hot-categories {
    .wrap-item {
        padding-left: 8px;
        padding-right: 8px;

        @media screen and (max-width: 768px) {
            padding-left: 5px;
            padding-right: 5px;
            display: inline-block;
            min-width: 161px;
        }
    }

    .sectionBlock__content {
        overflow: hidden;
        .mobile-div-fk-slide {
            display:flex;
            overflow-x: scroll;
        }
    }

    .slick-disabled {
        display: none;
    }

    .slick-slider {

        .slick-next,
        .slick-prev {
            fill: #fff;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            opacity: 1;
            position: absolute;
            top: 50%;
            margin-top: -13px;
            cursor: pointer;
            transition: opacity 0.3s, stroke 0.3s;
            z-index: 1;
            padding: 0;
            font-size: unset;
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));
            transition: all 0.2s;
            box-shadow: none;
            transition: .25s;

            &::before {
                line-height: unset;
                display: none;
            }

            &:hover {
                background-color: #fff;
                // border: 1px solid #FF7D55;
                filter: none;

                polyline,
                circle {
                    stroke: #FF7D55;
                    opacity: 1;
                }
            }

            svg,
            circle {
                fill: none;
            }

            circle {
                stroke: none;
            }
        }

        .slick-next {
            right: 3px !important;
            transform: rotateY(180deg)
        }

        .slick-prev {
            left: 3px !important;
            margin-top: 0;
        }
    }



    .slick-list {
        width: calc(100% + 190px);
        margin-left: -8px;

        @media screen and (max-width: 768px) {
            width: calc(100% + 100px);
            margin-left: -5px;
        }
    }

    // .slick-list {
    //     padding: 0 80px 0 0 !important;
    // }
    // .slick-track {
    //     padding-right: 40px;
    // }

    .category-item {
        border-radius: 6px;
        background-color: rgba(0,90,255,0.05);
        text-align: center;
        padding: 20px;
        position: relative;

        img {
            margin: 0 auto;
        }

        a:hover .title {
            color: #ff7d55;
        }

        .wrap-name {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .title {
            font-size: 16px;
            font-weight: 600;
            color: #333;
            margin-bottom: 0;
            line-height: 1.5;
			text-transform: uppercase;
        }

        .total {
            color: #757575;
            font-size: 14px;
            margin-bottom: 0;
        }
    }

}
