.promotion-jobs {
	box-sizing: border-box;
	border: 1px solid rgba(0, 90, 255, 0.3);
	border-radius: 6px;
	background-color: rgba(0, 90, 255, 0.1);
	padding: 24px;

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;

		h3 {
			font-weight: 500;
			color: #333;
			font-size: 24px;

			span {
				font-weight: 700;
			}
		}

		.show-all {
			color: #00b9f2;
			font-size: 16px;
			font-weight: 600;
			cursor: pointer;
		}
	}

	.list-job {
		margin-right: -15px;

		.job-item {
			background-color: #fff;
			border: 1px solid #d9f5fd;
			padding: 14px 16px 14px 10px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			flex-basis: calc(33.33% - 15px);
			margin: 0 15px 15px 0;

			.job-logo {
				img {
					width: auto;
					height: 58px;
				}
			}

			.job-content {
				padding-left: 16px;

				.job-title {
					color: #333;
					font-weight: 600;
					font-size: 16px;
					text-transform: capitalize;
					margin-bottom: 3px;
				}

				.job-company {
					color: #333;
					font-size: 14px;
				}
			}
		}
	}

	/* Christmas theme */
	&.christmas {
		position: relative;
		&::after {
			content: ' ';
			position: absolute;
			right: 0;
			bottom: 0;
			background-image: url(/assets-page-container/images/christmas/bottom_tag.svg);
			background-size: contain;
			width: 351px;
			height: 161px;
		}
	}
	/* Lunar new year theme */
	&.lunar-new-year {
		position: relative;
		&::after {
			content: ' ';
			position: absolute;
			right: 0;
			bottom: 0;
			background-image: url(https://images.vietnamworks.com/images/bottom_tag_2.svg);
			background-size: contain;
			width: 179px;
			height: 161px;
		}
	}
}

@media all and (max-width: 991px) {
	.promotion-jobs {
		padding: 18px 12px;

		.jobBlock {
			margin-bottom: 12px;
		}
	}
}
