#modal-banner-mobile {
	z-index: 1000;
	animation-duration: 0.2s;
	@include touch {
		& {
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
		.modal-dialog {
			height: 100%;
			width: 100%;
		}
		.modal-content {
			max-height: none;
			margin: 0;
		}
		.banner-body__bottom {
			padding: 15px;
		}
		.banner-body__bottom-title {
			font-weight: 500;
			margin: 0 20px;
		}
		.banner-body__bottom-content svg {
			margin-right: 15px;
		}
	}
}

.banner-body {
	padding: 0;

	&__bottom {
		background-color: #51B8ED;
		//background-size: cover, 400px;
		//@media (max-width: 320px) {
		//	background-image: linear-gradient(-180deg, #c1dce800, #51B8ED 10%), url('https://images.vietnamworks.com/img/mapsmobile.jpg');
		//}
		height: 100vh;
		&-container {
			display: flex;
			flex-direction: column;
			font-weight: 400;
			padding-top: 28vh;
			@media (max-width: 320px) {
				padding-top: 8vh;
			}

		}

		&-container-2 {
			display: flex;
			flex-direction: column;
			width: 100vw;
			padding: 0 20%;
		}

		&-title {
			font-size: 2.1em;
			color: #FFFFFF;
			letter-spacing: -1.41px;
			text-align: center;
			display: flex;

			line-height: 1.2;

			justify-content: center;
			padding-bottom: 15px;
		}

		&-content {
			display: flex;
			font-size: 1.2em;
			color: #FFFFFF;
			letter-spacing: 1.16px;
			line-height: 25px;
			margin-bottom: 7px;
			align-items: center;

			& .fa {
				font-weight: bold;
				max-width: 40px;
				width: 100%;
			}
		}

		&-button {

			margin-top: 50px;
			display: flex;
			justify-content: center;
			width: 100%;

			& button {
				background-color: #FF7D55;
				border-color: #f28809;
				border: 3px solid #FFFFFF;
				border-radius: 11px;
				width: 288px;
				height: 69px;
				font-size: 24px;
				font-weight: 600;
				color: #fff;
			}
		}

		&-no-thanks {

			margin-top: 10px;
			display: flex;
			justify-content: center;
			width: 100%;

			& a {
				color: white;

				text-decoration: underline;
				font-size: 17px;
				color: #FFFFFF;
				letter-spacing: 1.39px;

				&:hover {

					color: #FF7D55;
					text-decoration: none;
				}
			}
		}

	}

}

.banner-content {
	background: transparent;
	overflow: hidden;
}

.linkStore {
	visibility: hidden;
}

.skill-banner {

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__modal {
		position: absolute;
		top: 140px;
		left: 0px;
		max-width: 660px;
		&-body {
			padding: 40px;
			& .close {
				position: absolute;
				top: 10px;
				font-weight: 300;
				right: 20px;
				font-size: 34px;
				line-height: 27px;
				z-index: 1;
				text-indent: 0;
				width: 30px;
				height: 30px;
			}
		}
		@media (max-width: 767px) {
			width: 100%;
			height: 100vh;
			top: 0px;
			&-body {
				padding: 15px;
			}
		}
	}
	&__text {
		&-1 {
			font-size: 32px;
			color: #555555;
			text-align: center;
			width: 400px;
			@media (max-width: 767px) {
				width: 100%;
				font-size: 1.9em;
			}
		}
		&-2 {
			font-size: 18px;
			color: #4A4A4A;
			text-align: center;
			line-height: 25px;
			margin-top: 20px;
			width: 100%;
			@media (max-width: 767px) {
				width: 100%;
				font-size: 1.2em;
			}
		}
	}
	&__bottom-button {
		margin-top: 70px;
		display: flex;
		justify-content: center;
		width: 100%;
		@media (max-width: 767px) {
			margin-top: 20px;
		}
		& button {
			background: #FF7D55;
			border: 1px solid #F5A623;
			font-size: 16px;
			color: #FFFFFF;
			letter-spacing: -0.29px;
			text-align: center;
			width: 100%;
		}
	}

}
