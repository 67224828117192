.slick-slide, .slick-slide * { outline: none !important; }

.slick-dots {
	bottom: 1rem;

	&_bottom {
		top: initial;
		bottom: -32px;
	}

	&_type-hero {
		bottom: 100px;
	}
}

.slider_type-swipe {
	.slick-list {
		padding: 0 !important;
	}
}

.slick-custom-dot {
	height: 10px;
	width: 10px;
	background-color: transparent;
	border: 1px solid white;
	border-radius: 50%;
	display: inline-block;

	&_black {
		border: 1.33px solid #484848;
	}

	&_company-spotlight {
		bottom: -20px;
	}
}

.slick-active {
	.slick-custom-dot {
		background-color: white;
		&_black {
			background-color: #484848;
		}
	}
}

.slick-slider {
	.slick-prev, .slick-next {
		top: 146px;
		width: 44px;
		height: 44px;
		padding: 10px;
		display: inline-block;
		background-color: white;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.43);
		border-radius: 50%;
		transition: background-color .3s;

		svg {
			width: 100%;
			height: 100%;
			fill: #484848;
			stroke: 1px solid #535353;
		}

		&:hover {
			background-color: $gray236;
		}
	}
	.slick-prev {
		left: 3% !important;
		z-index: 1;
	}
	.slick-next {
		right: 3% !important;
		z-index: 1;
	}
}

.slick-prev:before, .slick-next:before {
	content: '';
}

@include tablet {
	.slick-dots {
		&_type-hero {
			bottom: 35px;
		}
	}
}

@include touch {
	.slick-custom-dot {width: 8px; height: 8px;}
	.slick-dots li {margin: 0;}
	//.slick-dots_type-hero {
	//	bottom: 150px;
	//}
}

.hr-insider {
	.slick-slider {
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
	
	.slick-list.draggable {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}	

	.slick-dots_bottom {
		bottom: -26px;
	}
}

@include mobile {
	.slick_heroBanner {
		margin-bottom: 28px;
		.slick-list {
			overflow: visible;
		}

		.slick-dots {
			bottom: -28px;
			

			.slick-active {
				.slick-custom-dot {
					background-color: $color-dot-dark;
					border-color: $color-dot-dark;
					width: 10px;
					height: 10px;
				}
			}

			.slick-custom-dot {
				vertical-align: middle;				
				border-color: $color-dot-dark-border;							
			}
		}
	}
}

