.sideNavbar {
    $root: &;
    height: 100%;
    width: 90vw;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -90vw;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
	transition: .5s ease;
	-ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);

    &_full {
        width: 100vw;
    }

    &_from-right {
        left: unset;
        right: -90vw;
        
        &#{$root}_slide {
			-ms-transform: translateX(-90vw);
            -webkit-transform: translateX(-90vw);
            transform: translateX(-90vw);

            &#{$root}_full {
				-ms-transform: translateX(-100vw);
                -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
            }
        }

        &#{$root}_full {
            right: -100vw;
        }
    }
    
    .column {
        padding: 0;
    }

    &_slide {
		-ms-transform: translateX(90vw);
        -webkit-transform: translateX(90vw);
        transform: translateX(90vw);
    }

    &__heading {
        padding: 30px 30px 11px 30px;
        &.sideNavbar__heading_same-nav{
            padding: 10px 3px 1px 25px;  
        }
    }

    &__heading-item {
        font-size: 1.25rem;
        font-weight: bold;
        color: white;
    }

    &__heading_same-nav {
        .columns {
            height: $nav-height-mobile;
        }
    }

    &__logo {
        min-width: 150px;
        img {
            max-height: 2.6rem;
        }
    }

    &__heading_primary {
        background-color: $color-primary;
    }

    &__menu {
        padding: 1.75rem;
        
    }

    &__menu-item {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 1.6rem;
        font-size: 17px;
        font-weight: 500;
        color: black;
        letter-spacing: 0.03rem;
        a {
            color: black;
        }
    }

    &__sub-menu {
        margin-left: 1.5rem;
        margin-top: 1.067rem;
        display: block;
        width: 100%;
        a {
            color: $color-primary;
        }
    }

    &__menu-item_link {
        margin-bottom: 1.067rem;
    }

    &__profile-icon {
        width: 60px;
        height: auto;   
        margin: .5rem 0;     

        &_rounded {
            border-radius: 50%;
        }
    }    
}
