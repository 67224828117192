.profilePicture {    
    border: 2px solid $color-primary;
    border-radius: 50%;    
    &_in-nav#{&} {    
        max-height: 38px;
    }

    &_border-white {
        border-color: white;
    }
}