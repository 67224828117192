.tag {
    border-radius: 3px;
    text-transform: uppercase;
   
    &_hot#{&} {
        font-weight: bold;
        color: white;
        background: #FF4A53;
        border: 1px solid #ED4D1B;
        border-radius: 4px;
    }

    &_round#{&} {
        border-radius: 50%;
    }

    &_new#{&} {
        font-weight: 500 !important;
        font-size: 11px !important;
        font-weight: bold !important;
        letter-spacing: 0 !important;
        color: #005AFF !important;
        border: 1px solid rgba(0,90,255,0.3);
        border-radius: 4px;
        background-color: rgba(0,90,255,0.1);
        box-sizing: border-box;
    }

    &_nav {
        position: relative;
        top: -8px;
        left: 1px;
        padding-left: .3rem !important;
        padding-right: .3rem !important;
    }

    &_is-small {
        font-size: .5rem;
    }

    &:not(body).tag_is-small{
        font-size: 7px;
        width: 20px;
        height: 10px;;
        border-radius: 1.6px;
        background-color: $redTag;
        color: white;
    }

    &:not(body) {
        font-size: 0.67rem;
        height: 1rem;
    }
    &-round.tag {
        width: 24px;
        height: 24px;
        font-weight: bolder;
        border-radius: 20px;
    }
}