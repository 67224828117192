.bubble {
	position: relative;
	border-radius: 3.2px 3.2px 3.2px 3.2px;
	padding: 16px 0 16px 8px;
	background-color: white;
	display: flex;
	align-items: center;

	&__wrapper {		
		background: white;		
		box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);			
		&.columns {
			margin-bottom: 0;
		}	

		@include tablet {
			background: rgba(0,0,0,.2);
			box-shadow: none;			
		}
		
		@media screen and (min-width: 1280px) and (max-width: 1365px) {			
			position: absolute;
			top: 120px;
			left: 12px;
			width: 100%;
		}
		
		@media screen and (min-width: 1440px) {			
			position: absolute;
			top: 120px;
			left: 12px;
			width: 100%;
		}		
	}

	&__image-background {
		width: 110px;
		height: 42px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}	
}

@include tablet {
	.bubble {
		padding: 8px;
		border-radius: 0 3.2px 3.2px 3.2px;

		&__image-background {
			width: 122px;
			height: 50px;			
		}

		&__wrapper {
			padding: 11.25px;
		}
	}
	

	.bubble:after {
		content: '';
		position: absolute;
		left: -2px;
		top: 0;
		width: 0;
		height: 0;
		border: 12px solid transparent;
		border-right-color: white;
		border-left: 0;
		border-bottom: 0;
		margin-left: -10px;
		margin-bottom: -20px;
	}
}

