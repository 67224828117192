.notificationModal {
	display: none;
	-webkit-transform: translate3d(0,0,0);
  	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	z-index: $z-index-modal;

	&.is-active {
		position: fixed;
		display: block;
		top: 0;
		right: 0;
		height: 150%; // prevent scroll to the edge on mobile browser
		width: 100%;				
	}
	.navbar-dropdown {
		max-width: 100%;
		width: 100%;	
	}
	.navbar-item {
		align-items: center;
		&:hover, &:focus {
			&:not(.navbar-item_heading) {
				background-color: $color-primary;
				@include mobile {
					background-color:white;
					color:$color-text;
				}
			}
			
		}
	}

	& .navbar__notifications-container {
		
		
		& .navbar-item {
			&:hover, &:focus {
				background-color: $color-primary;
				@include mobile {
					background-color:white;
					color:$color-text;
				}
			}
		}		
	}
}

// Start integration of VNWHeader project
.vnwHeader {
	&__notification {		
		.navbar {
			background: transparent;
			height: auto;
			min-height: unset;

			.navbar-dropdown {
				top: 34px;
				font-weight: initial;
                text-transform: initial;
			}

			.icon {
				color: white;
				
				&_overlay {
					color: $color-secondary;
					right: -5px;
					bottom: -6px;
					
					svg {
						stroke: none;
					}
				}
			}	
			
			.navbar-item {
				text-align: initial;
			}
		}

		.navbar-item.has-dropdown {
			padding: 0;
			&>.navbar-item {
				padding: 0;
			}
		}
	}
}
// End integration



@include mobile {
	html.modal-open-notifications {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
	}

	body.modal-open-notifications {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
	}
}
