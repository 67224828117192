.sectionBlock_company-spotlight {
    .pagination-wrapper {
        margin-top: 20px;
    }
}

.spotlightBlock {
    position: relative;

    &__background {
        background-size: cover;
        background-repeat: no-repeat;
        width: auto;
        height: 0;
        padding-bottom: 26.04166667%;
        border-radius: 6px;
    }

    &__wrapper {
        width: 84.1%;
        padding: 17.5px 20px;
        background-color: #f4f4f7;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -105px;
    }

    &__inner {
        display: flex;
        position: relative;
        align-items: center;
        padding: 24px;
        background-color: #fff;
        border-radius: 4px;
    }

    &__logo {
        .image {
            width: 120px;
            height: 120px;
            background-color: #fff;
            border: 1px solid #eaeaf0;
            margin: 0;

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                object-fit: contain;
            }
        }
    }

    &__content-wrapper {
        padding-left: 25px;
    }

    &__title {
        color: #333333;
        font-weight: 600;
        font-size: 24px;

        h3 {
            color: #333333;
        }
    }

    &__message {
        color: #FF7D55;
        font-size: 13px;
        font-style: italic;
        margin-bottom: 5px;
    }

    &__content {
        font-size: 15px;
    }

    &__mainContent {
        margin: 0;

        a {
            margin-left: 6px;
            color: #005aff;
            
            &:hover {
                color: #FF7D55;
            }
        }
    }
}

@media all and (max-width: 991px) {
    .spotlightBlock {
        &__background {
            height: 147px;
        }

        &__wrapper {
            width: 97%;
            padding: 8px;
            margin-top: -59.5px;
        }

        &__content-wrapper {
            padding-left: 15px;
            padding-right: 15px;
            width: calc(100% - 30px)
        }

        & .swiper-slide {
            width: 80%;
        }

        &__logo {
            .image {
                width: 70px;
                height: 70px;
            }
        }

        &__title {
            margin-bottom: 5px;

            h3 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                margin-bottom: 0;
            }
        }

        &__message {
            font-size: 13px;
            margin-bottom: 0;
        }

        &__inner {
            padding: 12px;
            justify-content: initial;
        }

        &__content {
            display: none;
        }
    }
}

.overlay-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}