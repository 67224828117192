$ribbon-color: $color-secondary;
$ribbon-back-color: darken($ribbon-color, 15%);

.ribbon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	width: 67px;
	height: 19px;
	color: #fff;
	background-color: #00b9f2;
	border-radius: 4px;
	font-size: 11px;
	text-transform: uppercase;
	text-align: center;
}
