.justify-between {
    justify-content: space-between !important;
}

.justify-around {
    justify-content: space-around !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.align-center {
    align-items: center !important;
}

@include tablet {
	.justify-end-tablet {
		justify-content: flex-end !important;
	}
}

@include mobile {
	.align-end-mobile {
		align-items: flex-end !important;
	}

	.direction-row-reverse-mobile {
		flex-direction: row-reverse;
	}
}

.space-evenly {
    justify-content: space-evenly !important;
}
